export const rideStatus = {
  UNKNOWN_TRIP_STATUS_Default: "REQUESTED",
  NEW_Newly_created_trip: "REQUESTED",
  ENROUTE_TO_PICKUP: "REQUESTED",
  ARRIVED_AT_PICKUP: "REQUESTED",
  ARRIVED_AT_INTERMEDIATE_DESTINATION: "ONGOING",
  ENROUTE_TO_INTERMEDIATE_DESTINATION: "ONGOING",
  ENROUTE_TO_DROPOFF: "ONGOING",
  CANCELED: "CANCELLED",
  COMPLETE: "COMPLETED",
};

export function calculateMinutesUntilDropoff(utcTimeString) {
  // Parse the provided UTC time string into a Date object
  const dropoffDate = new Date(utcTimeString);

  // Convert dropoff time to seconds since Unix epoch
  const dropoffTimeInSeconds = Math.floor(dropoffDate.getTime() / 1000);

  // Get the current time in seconds since Unix epoch
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);

  // Calculate the time difference in seconds
  const timeDifferenceInSeconds = dropoffTimeInSeconds - currentTimeInSeconds;

  // Convert the time difference to minutes, ensuring it's not negative
  return Math.max(0, Math.floor(timeDifferenceInSeconds / 60));
}

export function calculateDropoffTime(utcTimeString) {
  const etaInMinutes = calculateMinutesUntilDropoff(utcTimeString);
  let dropTime = new Date();
  dropTime.setMinutes(dropTime.getMinutes() + etaInMinutes);

  return dropTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

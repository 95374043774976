import { useEffect, useRef, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import pinPointSvg from "../assets/ridetracking/Pin icon.svg";
import drop from "../assets/ridetracking/Drop Location.svg";
import CarTrackSvg from "../assets/CarTrack.svg";
import { rideStatus } from "../const/map";

const JourneySharingComponent = ({
  apiResponse,
  onTripChange,
  status,
  trip,
}) => {
  const mapContainerRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [locationProvider, setLocationProvider] = useState(null);
  const previousStatus = useRef(null);
  const [polyline, setPolyline] = useState("");

  const DEFAULT_POLLING_INTERVAL_MS = 3000;
  const ICON_OPTIONS = { USE_DEFAULT: "default" };
  const JourneySharingUserOptions = {
    destinationIcon: status === "REQUESTED" ? pinPointSvg : drop,
    originIcon: drop,
    vehicleIcon: CarTrackSvg,
    showAnticipatedRoutePolyline: true,
    showTakenRoutePolyline: true,
  };

  useEffect(() => {
    if (!apiResponse?.id || !window.google || flag || !mapContainerRef.current)
      return;

    setFlag(true);
    const id = apiResponse.id;

    const authTokenFetcher = async () => {
      // const response = await axiosInstance.get(
      //   `/utils/get-fleet-engine-token/?ride_id=${id}`
      // );

      const fleetToken = apiResponse.fleet_engine_token;

      return {
        token: fleetToken.token || "",
        expiresInSeconds: fleetToken?.expiry_time_in_min
          ? fleetToken?.expiry_time_in_min * 60
          : 0,
      };
    };

    const provider =
      new window.google.maps.journeySharing.FleetEngineTripLocationProvider({
        projectId: process.env.REACT_APP_FLEET_ENGINE_PROJECT_ID,
        authTokenFetcher,
        tripId: id,
        pollingIntervalMillis: DEFAULT_POLLING_INTERVAL_MS,
        snapToRoute: true,
        originMarkerCustomization: (params) => {
          const tripStatus = params?.trip?.status;
          if (rideStatus[tripStatus] === "ONGOING") {
            params.marker.setIcon(null);
            params.marker.setVisible(false);
            return;
          }
          params.marker.setIcon(pinPointSvg);
        },
        destinationMarkerCustomization: (params) => {
          const destinationMarker = drop;
          const tripStatus = params?.trip?.status;
          if (rideStatus[tripStatus] === "REQUESTED") {
            params.marker.setIcon(null);
            params.marker.setVisible(false);
            return;
          }

          params.marker.setVisible(true);
          if (
            JourneySharingUserOptions.destinationIcon !==
            ICON_OPTIONS.USE_DEFAULT
          ) {
            params.marker.setIcon(
              destinationMarker ||
                JourneySharingUserOptions.destinationIcon.icon
            );
          }
        },
        remainingPolylineCustomization: { visible: false },
        takenPolylineCustomization: { visible: false },
        activePolylineCustomization: (params) => {
          for (const polylineObject of params.polylines) {
            polylineObject.setOptions({
              strokeColor: "#4289e9",
              strokeOpacity: 1.0,
              strokeWeight: 8,
            });
          }
        },
        vehicleMarkerCustomization: (params) => {
          if (
            JourneySharingUserOptions.vehicleIcon !== ICON_OPTIONS.USE_DEFAULT
          ) {
            params.marker.setIcon({
              url: CarTrackSvg || JourneySharingUserOptions.vehicleIcon.icon,
              scaledSize: new window.google.maps.Size(40, 40), // Adjust size
              anchor: new window.google.maps.Point(20, 20), // Align the icon properly
              zIndex: 999, // Ensure it renders above the polyline
            });
          }
        },
      });

    const journeySharingMapView =
      new window.google.maps.journeySharing.JourneySharingMapView({
        element: mapContainerRef.current,
        locationProvider: provider,
        mapOptions: {
          // mapId: mapId,
          mapTypeControl: true,
          streetViewControl: true,
        },

        // anticipatedRoutePolylineSetup: (params) => {
        //   return {
        //     polylineOptions: {
        //       ...params.defaultPolylineOptions,
        //     },
        //     visible: false,
        //   };
        // },

        // takenRoutePolylineSetup: ({ defaultPolylineOptions }) => ({
        //   polylineOptions: {
        //     ...defaultPolylineOptions,
        //     strokeColor: "#000",
        //     strokeOpacity: 0.8,
        //     strokeWeight: 4,
        //   },
        //   visible: false,
        // }),
      });

    const bounds = new window.google.maps.LatLngBounds();
    provider.addListener("update", (e) => {
      const vehicleLocation = e.trip?.latestVehicleLocationUpdate?.location;
      const origin = e.trip?.origin?.location;
      const destination = e.trip?.destination?.location;

      const tripData = e.trip;

      if (origin) bounds.extend({ lat: origin.lat, lng: origin.lng });
      if (destination && rideStatus[tripData?.status] !== "REQUESTED")
        bounds.extend({ lat: destination.lat, lng: destination.lng });
      if (vehicleLocation) {
        bounds.extend({ lat: vehicleLocation.lat, lng: vehicleLocation.lng });
      }

      if (!bounds.isEmpty()) {
        journeySharingMapView.map.fitBounds(bounds);
      }

      const path = tripData?.remainingWaypoints[0]?.path;

      if (Array.isArray(path)) {
        // Create a polyline using the array
        const polylineRoute = new window.google.maps.Polyline({
          path: path, // Use the array directly
          geodesic: true,
          strokeColor: "#4285f4", // Customize color
          strokeWeight: 8.0, // Customize thickness
        });
        // Add the polyline to the map
        const map = journeySharingMapView.map;
      }

      onTripChange(e.trip);
      previousStatus.current = rideStatus[tripData?.status];
    });

    provider.addListener("error", (e) => {
      console.error("Trip Error:", e);
      onTripChange(null);
    });

    setLocationProvider(provider);

    return () => {
      journeySharingMapView.map?.set(null);
    };
  }, [apiResponse, flag]);

  useEffect(() => {
    if (locationProvider && apiResponse?.id) {
      window.google.maps.Settings.getInstance().experienceIds = [
        `trip_${apiResponse.id}`,
      ];
      locationProvider.tripId = `trip_${apiResponse.id}`;
    }
  }, [locationProvider, apiResponse]);

  return (
    <div
      ref={mapContainerRef}
      style={{
        width: "100%",
        maxWidth: "480px",
        margin: "auto",
        height: "100%",
      }}
    />
  );
};

export default JourneySharingComponent;
